import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { adminRoutes } from "./pages/admin/Route";
import { botRoutes } from "./pages/bot/Route";
import { loginRoutes } from "./pages/login/Route";
import AuthProvider from "./provider/AuthProvider";
import { urls } from "./urls";
import { ErrorPage } from "./pages/error/ErrorPage";
import { sharedAdminRoutes } from "./pages/admin/SharedRoute";
import { embedRoutes } from "./pages/embedBot/Route";

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Navigate to={urls.login} replace />,
      },
      loginRoutes,
      botRoutes,
      adminRoutes,
      sharedAdminRoutes,
      embedRoutes,
    ],
  },
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
